import { get } from "axios"
import React, { useEffect, useState } from "react"
import { logo } from "../../constants/logo"
import { RADIOPLAYER_SOCIALS } from "../../fixtures/SocialsData"
import { getRadioTracksEndpoint } from "../../services/api"
import Icon from "../Icon/Icon"
import Logo from "../Logo/Logo"
import PlaylistTrack from "../PlaylistTrack/PlaylistTrack"

export default function RadioPlayerNostalgie({ apiId, radio }) {
  const [currentTrack, setCurrentTrack] = useState(null)
  const [tracks, setTracks] = useState([])

  useEffect(() => {
    async function fetchTracks(apiId) {
      try {
        await get(getRadioTracksEndpoint(apiId)).then(response => {
          if(response?.data?.data?.length > 0) {
            setCurrentTrack(response.data.data[0])
            response.data.data.shift()
            setTracks(response.data.data)
          }
        })
      } catch (errror) {
        setCurrentTrack(null);
        setTracks([]);
      }
    }
    fetchTracks(apiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = () => setTimeout(() => fetchTracks(apiId), 30000)
    const timerId = timer()
    return () => {
      clearTimeout(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrack])

  async function fetchTracks(apiId) {
    try {
      await get(getRadioTracksEndpoint(apiId)).then(response => {
        if(response?.data?.data?.length > 0) {
          setCurrentTrack(response.data.data[0])
          response.data.data.shift()
          setTracks(response.data.data)
        }
      })
    } catch (errror) {
      setCurrentTrack(null);
      setTracks([]);
    }
  }

  return (
    <>
      <div className="radio radio--nostalgie">
        <div className="radio--inner">
          <div className="radio--header">
            <div className="radio--logo">
              <Logo color={logo.neutral} />
              <p className="radio--logo-radio-player">
                <span>radio</span>
                <span>player</span>
              </p>
            </div>
            {currentTrack && (
              <div className="current-track">
                <div className="current-track__cover">
                  <img
                    className="current-track__cover-img"
                    src={currentTrack.cover_art}
                    alt={`${currentTrack.title} by ${currentTrack.artist} album cover`}
                  />
                </div>
                <div className="current-track__content">
                  <div className="current-track__track">
                    <p className="current-track__track-title">
                      {currentTrack.title}
                    </p>
                    <p className="current-track__track-artist">
                      {currentTrack.artist}
                    </p>
                  </div>
                  <p className="current-track__live">
                    <Icon name="live" />
                    <span>Live</span>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="recent-tracks">
            <h2 className="u-visually-hidden">Playlist</h2>
            <ul className="recent-tracks--body">
              {tracks.map((track, i) => {
                return (
                  <li key={i}>
                    <PlaylistTrack
                      track={track}
                      hasBorder={i < tracks.length - 1}
                    />
                  </li>
                )
              })}
            </ul>
            {radio.slug === 'playnostalgie' &&
              <div className="recent-tracks--btn">
                <a
                  href={`/playlist/${radio.slug}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn--primary btn--rounded btn--large"
                >
                  <span className="content">Volledige playlist</span>
                  <Icon name="chevron-right" />
                </a>
              </div>
            }
          </div>
        </div>
      </div>
      <ul className="radio--socials">
        {RADIOPLAYER_SOCIALS.map(({ url, icon, title }) => (
          <li className="radio--socials-item" key={title}>
            <a
              className="radio--socials-item-link"
              href={url}
              target="_blank"
              rel="noreferrer"
              title={title}
            >
              <Icon className="radio--socials-item-icon" name={icon} />
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}
